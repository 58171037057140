import React, { useContext } from 'react'
import orderBy from 'lodash/orderBy'
import moment from 'moment'
import PropTypes from 'prop-types'

import LangContext from 'context/LangContext'

import BasicAccordion from 'components/accordion/BasicAccordion'
import IconButton from 'components/button/IconButton'
import EmptyState from 'components/EmptyState'

import { RECEIPT_STATUS } from 'utils/constants'

const ListReceipts = ({ receipts, openModal, colored = false }) => {
  const { translate } = useContext(LangContext)

  const fullName = (receipt) => {
    if (receipt?.uploadedByContact) return `${receipt.contact.firstname} ${receipt.contact.lastname}`
    if (receipt?.uploadedBy) return `${receipt.employee.firstName} ${receipt.employee.lastName}`

    return translate('components.Extrahub.ListReceipts.unknownContact')
  }

  const statusColors = {
    [RECEIPT_STATUS.PENDING]: 'text-orange-500',
    [RECEIPT_STATUS.REJECTED]: 'text-red-500',
    [RECEIPT_STATUS.VALID]: 'text-green-500',
    [RECEIPT_STATUS.APPROVED]: 'text-green-500',
    [RECEIPT_STATUS.INSUFFICIENT_BUDGET]: 'text-blue-500',
    [RECEIPT_STATUS.PAYMENT_FAILED]: 'text-blue-500'
  }

  return (
    <>
      <BasicAccordion title={translate('components.Extrahub.ListReceipts.titleAccordion')}>
        {receipts?.length ? (
          <table className="w-full">
            <tbody>
              {orderBy(receipts, 'createdAt', 'desc').map((receipt) => (
                <tr key={receipt.id}>
                  <td>
                    {`${fullName(receipt)} ${translate('common.on')} ${moment(receipt.createdAt).format(
                      'YYYY-MM-DD'
                    )} `}
                    [<span className={statusColors[receipt.status]}>{receipt.status}</span>]
                  </td>

                  {openModal && (
                    <td className="text-right">
                      <IconButton ghost compact icon="document-scanner" onClick={() => openModal(receipt)} />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <EmptyState
            title={translate('components.Extrahub.ListReceipts.EmptyState.title')}
            subtitle={translate('components.Extrahub.ListReceipts.EmptyState.subtitle')}
          />
        )}
      </BasicAccordion>
    </>
  )
}

ListReceipts.propTypes = {
  receipts: PropTypes.array,
  openModal: PropTypes.func,
  colored: PropTypes.bool
}

export default ListReceipts
