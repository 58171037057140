import React, { useContext, useMemo, useState } from 'react'
import { Field, useFormikContext } from 'formik'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import sum from 'lodash/sum'
import sumBy from 'lodash/sumBy'
import { arrayOf, number, object, string } from 'prop-types'

import LangContext from 'context/LangContext'

import Button from 'components/button/Button'
import Input from 'components/Input'
import Table, { StyledTable } from 'components/table'

import { AGREEMENT_TERMS, SORTING_DIRECTION } from 'utils/constants'
import { sortDataTableBy } from 'utils/helpers'

const NumberInput = ({ form, field, max, min = 0, ...props }) => {
  const { value, name } = field
  if (max && min && value > max) {
    form.setFieldValue(name, max)
  }
  // if (value < min && min < max) form.setFieldValue(name, min)
  return <Input {...{ form, field, max, min, ...props }} />
}

NumberInput.propTypes = {
  form: object,
  field: object,
  max: number,
  min: number
}

const SkuExceptionTable = ({ skus }) => {
  const { preferredLanguage } = useContext(LangContext)
  const formikContext = useFormikContext()

  const resetToSuggested = () => {
    formikContext.setFieldValue('exception.skus', formikContext?.initialValues.exception.skus)
  }
  return (
    <Table
      data={skus}
      columns={[
        {
          name: 'productId',
          header: 'Product',
          body: {
            labelGenFn: (record) => record.product[`${preferredLanguage}Name`]
          },
          footer: {
            type: 'customcell',
            render: (record) => <td colSpan="3">&nbsp;</td>
          }
        },
        {
          name: 'minQty',
          header: 'Min Qty',
          footer: {
            type: 'customcell',
            render: (record) => null
          }
        },
        {
          name: 'newMinQty',
          header: 'New min',
          body: {
            type: 'customcell',
            render: (record) => {
              return (
                <Field
                  name={`exception.skus.p${record.productId}.minQty`}
                  component={NumberInput}
                  small
                  type="number"
                  minQty={0}
                  validate={(v) => {
                    if (!v && v !== 0) return 'Required'
                    if (v < 0) return `Min: 0`
                  }}
                />
              )
            }
          },
          footer: {
            type: 'customcell',
            render: (record) => null
          }
        },
        {
          name: 'maxQty',
          header: 'Max Qty',
          footer: {
            type: 'customcell',
            render: (records, column) => {
              return (
                <td colSpan="2">
                  <Button type="button" primary onClick={resetToSuggested}>
                    Reset min/max
                  </Button>
                </td>
              )
            }
          }
        },
        {
          name: 'newMaxQty',
          header: 'New max',
          body: {
            type: 'customcell',
            render: (record) => {
              return (
                <Field
                  name={`exception.skus.p${record.productId}.maxQty`}
                  component={NumberInput}
                  small
                  type="number"
                  min={0}
                  validate={(v) => {
                    const { minQty } = formikContext.values.exception.skus[`p${record.productId}`]
                    if (!v && v !== 0) return 'Required'
                    if (v <= minQty) return 'Max too low'
                  }}
                />
              )
            }
          },
          footer: {
            type: 'customcell',
            render: (record) => null
          }
        }
      ]}
    />
  )
}

SkuExceptionTable.propTypes = {
  skus: arrayOf(object)
}

const RenderSkuTable = ({ skus, preferredLanguage, totalSuggestedQty, filterValue, formikContext }) => {
  const isForm = !!formikContext

  const [sortField, setSortField] = useState('product')
  const [sortDirection, setSortDirection] = useState(SORTING_DIRECTION.ASC)

  const filteredSkus = useMemo(() => {
    let result = skus

    if (filterValue) {
      const lowercaseFilter = filterValue.toLowerCase().trim()
      result = skus.filter(({ product }) => {
        return (
          product?.[`${preferredLanguage}Name`]?.toLowerCase().includes(lowercaseFilter) ||
          product?.[`${preferredLanguage}Brand`]?.toLowerCase().includes(lowercaseFilter) ||
          product?.materialGroup?.toLowerCase().includes(lowercaseFilter)
        )
      })
    }

    const sortedResult = [...result]

    if (sortField === 'product') {
      sortedResult.sort((a, b) => {
        const nameA = a.product[`${preferredLanguage}Name`]?.toLowerCase() || ''
        const nameB = b.product[`${preferredLanguage}Name`]?.toLowerCase() || ''

        if (sortDirection === SORTING_DIRECTION.ASC) {
          return nameA.localeCompare(nameB)
        }

        return nameB.localeCompare(nameA)
      })
    } else if (sortField === 'suggestedQty') {
      sortedResult.sort((a, b) => {
        const qtyA = +a.suggestedQty || 0
        const qtyB = +b.suggestedQty || 0

        if (sortDirection === SORTING_DIRECTION.ASC) {
          return qtyA - qtyB
        }

        return qtyB - qtyA
      })
    }

    return sortedResult
  }, [skus, filterValue, preferredLanguage, sortField, sortDirection])

  const totalFinalQty = useMemo(() => {
    if (isForm && !isEmpty(formikContext?.values?.finalQtys)) {
      return sum(Object.values(formikContext.values.finalQtys).filter(Boolean))
    }
    return sumBy(skus, ({ finalQty }) => +finalQty || 0)
  }, [isForm, formikContext?.values?.finalQtys, skus])

  const subtotalFinalQty = useMemo(() => {
    if (isForm && !isEmpty(formikContext?.values?.finalQtys)) {
      return sumBy(filteredSkus, ({ productId }) => +(formikContext.values.finalQtys[`p${productId}`] || 0))
    }
    return sumBy(filteredSkus, ({ finalQty }) => +finalQty || 0)
  }, [isForm, formikContext?.values?.finalQtys, filteredSkus])

  const subtotalSuggestedQty = useMemo(
    () => sumBy(filteredSkus, ({ suggestedQty }) => +suggestedQty || 0),
    [filteredSkus]
  )

  const originalSuggestedQtys = useMemo(() => {
    if (!isForm) return {}
    return skus.reduce((acc, { productId, suggestedQty }) => ({ ...acc, [`p${productId}`]: suggestedQty }), {})
  }, [isForm, skus])

  const resetToSuggested = () => {
    if (isForm && formikContext) {
      formikContext.setFieldValue('finalQtys', originalSuggestedQtys)
    }
  }

  const handleSort = (field) => {
    const newSortState = sortDataTableBy(field, { column: sortField, order: sortDirection })
    setSortField(newSortState.column)
    setSortDirection(newSortState.order)
  }

  return (
    <StyledTable>
      <tbody>
        <tr>
          <th onClick={() => handleSort('product')} style={{ cursor: 'pointer' }}>
            Product {sortField === 'product' && (sortDirection === SORTING_DIRECTION.ASC ? '↑' : '↓')}
          </th>
          <th onClick={() => handleSort('suggestedQty')} style={{ cursor: 'pointer' }}>
            Suggested Qty {sortField === 'suggestedQty' && (sortDirection === SORTING_DIRECTION.ASC ? '↑' : '↓')}
          </th>
          <th>Final Qty</th>
          <th>Min Qty</th>
          <th>Max Qty</th>
        </tr>
        {filteredSkus.map(({ product, productId, suggestedQty, finalQty, minQty, maxQty, isAssigned }) => {
          return (
            <tr key={productId}>
              <td>{product[`${preferredLanguage}Name`]}</td>
              <td>{suggestedQty}</td>
              <td>
                {isForm ? (
                  <Field
                    name={`finalQtys.p${productId}`}
                    component={NumberInput}
                    placeholder="0"
                    small
                    type="number"
                    min={isAssigned ? minQty : 0}
                    max={maxQty}
                    validate={(v) => {
                      if (!isAssigned && !v) return
                      if (v < minQty) return `Min: ${minQty}`
                      if (v > maxQty) return `Max: ${maxQty}`
                    }}
                  />
                ) : (
                  <b>{finalQty || '-'}</b>
                )}
              </td>
              <td>{minQty}</td>
              <td>{maxQty}</td>
            </tr>
          )
        })}
        <tr>
          <th className="footer">Subtotal</th>
          <td>{subtotalSuggestedQty}</td>
          <td>
            <b>{subtotalFinalQty}</b>
          </td>
          <td colSpan="2"></td>
        </tr>
        <tr>
          <th className="footer">Total</th>
          <td>{totalSuggestedQty}</td>
          <td>
            <b>{totalFinalQty}</b>
          </td>
          <td colSpan="2">
            {isForm && (
              <Button type="button" primary onClick={resetToSuggested}>
                Reset to suggested
              </Button>
            )}
          </td>
        </tr>
      </tbody>
    </StyledTable>
  )
}

RenderSkuTable.propTypes = {
  skus: arrayOf(object),
  preferredLanguage: string,
  totalSuggestedQty: number,
  filterValue: string,
  formikContext: object
}

const SkuTable = ({ skus, exceptionType, filterValue, formikContext }) => {
  const { preferredLanguage } = useContext(LangContext)
  const totalSuggestedQty = sumBy(skus, ({ suggestedQty }) => +suggestedQty || 0)
  const isMinMaxException = exceptionType === AGREEMENT_TERMS

  const sortedSkus = useMemo(
    () => sortBy(skus, ({ product }) => product[`${preferredLanguage}Name`]),
    [skus, preferredLanguage]
  )

  if (isMinMaxException) return <SkuExceptionTable skus={sortedSkus} />

  return (
    <RenderSkuTable
      skus={sortedSkus}
      preferredLanguage={preferredLanguage}
      totalSuggestedQty={totalSuggestedQty}
      filterValue={filterValue}
      formikContext={formikContext}
    />
  )
}

SkuTable.propTypes = {
  skus: arrayOf(object),
  exceptionType: string,
  filterValue: string,
  formikContext: object
}

SkuTable.defaultProps = {
  exceptionType: null,
  filterValue: '',
  formikContext: null
}

export default SkuTable
