import React, { useContext, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { func } from 'prop-types'
import KeyAccounts from 'views/Customer/KeyAccounts'

import LangContext from 'context/LangContext'

import { fetchCustomerConsumerOffers } from 'store/customers/actions'
import { customerConsumerOffers, customerKeyCards } from 'store/customers/selectors'
import { isDataKeyLoading } from 'store/dataFetches/selectors'

import EmptyState from 'components/EmptyState'
import Spinner from 'components/Spinner'

import { DATAKEY_TYPES, SECTOR_LEVELS } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const ConsumerOfferContent = ({ fetchCustomerConsumerOffers }) => {
  const { translate } = useContext(LangContext)
  const { sectorType, sectorId } = useParams()
  const [error, setError] = useState(null)

  useEffect(() => {
    document.title = translate('Actions - Commercial Offers')
  }, [translate])

  const dataKey = createDataKey(DATAKEY_TYPES.CUSTOMER_CONSUMER_OFFER, { customerId: sectorId })

  const { consumerOffers, keyCards, isLoading } = useSelector((state) => ({
    consumerOffers: customerConsumerOffers(state, { customerId: sectorId }),
    keyCards: customerKeyCards(state, { customerId: sectorId }),
    isLoading: isDataKeyLoading(state, { dataKey })
  }))

  const hasKeyAcctData = consumerOffers.length + keyCards.length

  useEffect(() => {
    let isMounted = true
    if (sectorType === SECTOR_LEVELS.CUSTOMER && sectorId) {
      fetchCustomerConsumerOffers(sectorId, dataKey).catch((error) => isMounted && setError(getErrorMessage(error)))
    }

    return () => {
      isMounted = false
    }
  }, [sectorType, sectorId, fetchCustomerConsumerOffers, dataKey])

  if (isLoading) return <Spinner icon="spinner" />

  if (error) return <EmptyState title={translate('app.warn.errorOccured')} subtitle={error} />

  if (!hasKeyAcctData) {
    return <EmptyState title={translate('app.warn.noDataAvailable')} subtitle={translate('close.noConsumerOffer')} />
  }

  return <KeyAccounts consumerOffers={consumerOffers} keyCards={keyCards} />
}

ConsumerOfferContent.propTypes = {
  fetchCustomerConsumerOffers: func.isRequired
}

export default connect(null, { fetchCustomerConsumerOffers })(ConsumerOfferContent)
