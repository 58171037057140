import React, { useContext } from 'react'
import moment from 'moment'
import { stripUnit } from 'polished'
import { bool, node, number, object, oneOfType, string } from 'prop-types'
import styled, { css } from 'styled-components'

import LangContext from 'context/LangContext'

import Button from 'components/button/Button'
import Icon from 'components/Icon'
import SmallCaps from 'components/SmallCaps'

import {
  GO_FUND_PAYMENT_TYPES,
  IMAGE_RECOGNITION,
  MINI_VOLUME_GROWTH_PROGRAM_WITH_TARGET_SELL_IN,
  MINI_VOLUME_GROWTH_PROGRAM_WITH_TARGET_SELL_OUT,
  POWER_HOUR
} from 'utils/constants'
import { formatCurrency } from 'utils/formatters'

import { goBlue, red, secondaryColor, white, yellow } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { H3, smallFontSize, tinyFontSize } from 'styles/typography'

const Name = styled(H3)`
  margin-bottom: ${spacing.tiny};
  padding-right: ${spacing.xxxLarge};
`

const ProgramDate = styled.div`
  display: flex;
  align-items: center;
  font-size: ${smallFontSize};
`

const Calendar = styled(Icon)`
  fill: ${secondaryColor};
  flex-shrink: 0;
  margin-right: ${spacing.small};
`

const PaymentDueWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};
  font-size: ${smallFontSize};
`

const Warning = styled(Icon)`
  flex-shrink: 0;
  margin-right: ${spacing.small};
`

const RequestExceptionButton = styled(Button)`
  border: 1px solid ${goBlue};
  color: ${goBlue};
`

const PayNowButton = styled(Button)`
  color: ${white};
  background-color: ${goBlue};
  margin-right: 5px;
  margin-bottom: 5px;
`

const TrackCartonSalesButton = styled(Button)`
  color: ${white};
  background-color: ${goBlue};
  margin-right: 5px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};

  &:last-child {
    margin-bottom: 0;
  }
`

const GoStatWrap = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${tinyFontSize};
  ${(props) =>
    props.color &&
    css`
      color: ${red};
    `};
  align-items: center;
`

export const GoStat = ({ label, value, color }) => {
  if (!label) return <div />
  return (
    <GoStatWrap color={color}>
      <b>{label}</b>
      <span>{value}</span>
    </GoStatWrap>
  )
}

GoStat.propTypes = {
  label: string,
  value: oneOfType([number, string]),
  color: string
}

export const ShareGrowthProgramStats = ({
  program: { programCardInfos, posCtnVolume },
  payout,
  isCompleted,
  children
}) => {
  const { targetShare, startingShare, l4Share, performance, perfColor } = programCardInfos

  return (
    <>
      <Row>
        <GoStat label="Target share" value={`${targetShare || '-'}%`} />
        <GoStat label="Starting share" value={`${startingShare || '-'}%`} />
        <GoStat label="L4 Share" value={`${l4Share || '-'}%`} />
        {posCtnVolume && <GoStat label="Performance" value={`${performance}%`} color={perfColor} />}
      </Row>
      {!isCompleted && (
        <Row>
          <GoStat label="Actuals sales to date" value={`${posCtnVolume || 0} ctns`} />
          <GoStat label="Pot. payout to date" value={payout || '-'} />
          {children || <GoStat />}
        </Row>
      )}
    </>
  )
}

ShareGrowthProgramStats.propTypes = {
  program: object.isRequired,
  payout: string,
  isCompleted: bool,
  children: node
}

export const TrackedProgramStats = ({ program, payout, isCompleted, children }) => {
  const { perCartonCost, programCardInfos } = program
  const { target, actualCtns, performance } = programCardInfos

  return (
    <>
      <Row>
        <GoStat label="Target volume" value={target || '-'} />
        <GoStat label="Per carton" value={`$${perCartonCost}`} />
        <GoStat label="Performance" value={`${performance || '-'}%`} />
      </Row>
      {!isCompleted && (
        <Row>
          <GoStat label="Actuals til date" value={`${actualCtns || 0} ctns`} />
          <GoStat label="Pot. payout to date" value={payout || '-'} />
          {children || <GoStat />}
        </Row>
      )}
    </>
  )
}

TrackedProgramStats.propTypes = {
  program: object.isRequired,
  payout: string,
  isCompleted: bool,
  children: node
}

const PaymentDue = ({ daysTilPayDue, finalCost, adjustmentSubmittedAt }) => {
  if (adjustmentSubmittedAt)
    return (
      <PaymentDueWrap>
        <Warning icon="warning" small />
        <span>
          Payment adjustment ${finalCost} submitted {moment(adjustmentSubmittedAt).format('MMMM D')}
        </span>
      </PaymentDueWrap>
    )
  if (daysTilPayDue === 0)
    return (
      <PaymentDueWrap style={{ color: red }}>
        <Warning icon="warning" small />
        <span>Last day to submit an exception</span>
      </PaymentDueWrap>
    )
  return (
    <PaymentDueWrap style={{ color: yellow }}>
      <Warning icon="warning" small />
      <span>
        {daysTilPayDue} day{daysTilPayDue === 1 ? '' : 's'} left to submit an exception
      </span>
    </PaymentDueWrap>
  )
}

PaymentDue.propTypes = {
  daysTilPayDue: number,
  finalCost: number,
  adjustmentSubmittedAt: string
}

const ExceptionButton = ({ programsPath, programId }) => {
  return (
    <RequestExceptionButton small to={`${programsPath}/${programId}/exception`} offlineDisabled>
      Request exception
    </RequestExceptionButton>
  )
}

ExceptionButton.propTypes = {
  programsPath: string,
  programId: string
}

const ActionButtons = ({ programsPath, programId, program, goFund }) => {
  const { translate } = useContext(LangContext)
  const { activeTarget, actionButtons, isAiProgram } = program.programCardInfos

  if (isAiProgram && actionButtons.canPayGoFund) {
    return (
      <Button full primary icon="document-scanner" to={`${programsPath}/${programId}/scan`} offlineDisabled>
        {translate('components.Extrahub.ExtraHubCard.payNowButton')}
      </Button>
    )
  }

  return (
    <>
      {actionButtons.canPayGoFund && (
        <PayNowButton to={`${programsPath}/${programId}/payment`} offlineDisabled>
          Pay now
        </PayNowButton>
      )}
      {actionButtons.canUpdateCartonSales && (
        <TrackCartonSalesButton to={`${programsPath}/${programId}/carton`} offlineDisabled>
          Update carton sales
        </TrackCartonSalesButton>
      )}

      {goFund.activity === POWER_HOUR ? (
        actionButtons.canAddCartonsTarget ? (
          <PayNowButton to={`${programsPath}/${programId}/target`} offlineDisabled>
            Add cartons target
          </PayNowButton>
        ) : activeTarget ? (
          <PayNowButton to={`${programsPath}/${programId}/target/${activeTarget.id}`} offlineDisabled>
            Update cartons target
          </PayNowButton>
        ) : null
      ) : null}

      {actionButtons.canRequestException && (
        <RequestExceptionButton to={`${programsPath}/${programId}/exception`} offlineDisabled>
          Request exception
        </RequestExceptionButton>
      )}
    </>
  )
}

ActionButtons.propTypes = {
  programsPath: string,
  programId: string,
  program: object,
  goFund: object
}

export const ProgramStartEnd = ({ small, startDate, endDate }) => {
  const { lang } = useContext(LangContext)
  return (
    <ProgramDate>
      <Calendar icon="calendar" small={small} />
      <span>{`${moment.utc(startDate).locale(lang).format('MMM D, YYYY')} - ${moment
        .utc(endDate)
        .locale(lang)
        .format('MMM D, YYYY')}`}</span>
    </ProgramDate>
  )
}

ProgramStartEnd.propTypes = {
  small: bool,
  startDate: string.isRequired,
  endDate: string
}

const PER_CARTON_COST_ACTIVITY = [MINI_VOLUME_GROWTH_PROGRAM_WITH_TARGET_SELL_OUT, IMAGE_RECOGNITION]
const VALID_ACTIVITY_ROWS = [
  MINI_VOLUME_GROWTH_PROGRAM_WITH_TARGET_SELL_OUT,
  MINI_VOLUME_GROWTH_PROGRAM_WITH_TARGET_SELL_IN,
  IMAGE_RECOGNITION
]

const GoFundProgramCard = ({ programsPath, program }) => {
  const {
    brandFocus,
    variantFocus,
    description,
    id,
    goFundId,
    frenchName,
    englishName,
    allocation,
    goFund,
    maxPayout,
    perCartonCost,
    targetVolume,
    ctnsBooked,
    programCardInfos
  } = program

  const {
    activeTarget,
    cartonsPayoutAvailable,
    programBudgetRemaining,
    remainingBudget,
    remainingCartons,
    totalPayments,
    isCartonBasedActivity
  } = programCardInfos

  const programId = `${id}_${goFundId}`

  const { lang } = useContext(LangContext)
  const renderActivityRow = (goFundActivity) => (
    <Row>
      <GoStat label="Target ctns" value={targetVolume} />
      {PER_CARTON_COST_ACTIVITY.includes(goFundActivity) && (
        <GoStat label="$ per ctn" value={formatCurrency(perCartonCost, { decimalPlaces: 0 })} />
      )}

      <GoStat label="Ctns sold" value={ctnsBooked || 0} />
      <GoStat label="Remaining ctns" value={remainingCartons} />
    </Row>
  )

  return (
    <div className="relative my-6  flex w-full flex-col gap-2 rounded-lg border border-slate-200 bg-white p-4 shadow-sm hover:border-slate-300 hover:shadow-md">
      <Name>{lang === 'fr' ? frenchName : englishName}</Name>
      <ProgramStartEnd small startDate={goFund.startDate} endDate={goFund.endDate} />
      <SmallCaps>
        {variantFocus || brandFocus} • {(goFund && goFund.paymentType) || 'offline'} payment
      </SmallCaps>

      <div className="mb-2 text-xs text-gray-400">
        {goFund && (
          <b>
            {goFund.activity} {goFund.packCount ? ` (${goFund.packCount})` : ''}
          </b>
        )}
        <p>{description}</p>
      </div>
      <SmallCaps>Program details</SmallCaps>
      <Row>
        <GoStat label="Program budget" value={formatCurrency(allocation.allocatedBudget, { decimalPlaces: 0 })} />
        <GoStat label="Budget rewarded" value={formatCurrency(allocation.spent, { decimalPlaces: 0 })} />
        <GoStat label="Program balance" value={formatCurrency(programBudgetRemaining, { decimalPlaces: 0 })} />
      </Row>
      <SmallCaps>Store details</SmallCaps>
      <Row>
        <GoStat label="Max payout" value={formatCurrency(maxPayout, { decimalPlaces: 0 })} />
        <GoStat label="Store Rewarded" value={formatCurrency(totalPayments, { decimalPlaces: 0 })} />
        <GoStat label="Store balance budget" value={formatCurrency(remainingBudget, { decimalPlaces: 0 })} />
        {isCartonBasedActivity && (
          <GoStat label="Cartons payout" value={formatCurrency(cartonsPayoutAvailable, { decimalPlaces: 0 })} />
        )}
      </Row>
      {program.activity === POWER_HOUR && activeTarget && (
        <>
          <SmallCaps>Active target</SmallCaps>
          <Row>
            <GoStat
              label="Target amount"
              value={formatCurrency(activeTarget.cartonTargetAmount, { decimalPlaces: 0 })}
            />
            <GoStat label="Per carton amount" value={formatCurrency(perCartonCost, { decimalPlaces: 0 })} />
            <GoStat
              label="Total amount"
              value={formatCurrency(activeTarget.cartonTargetAmount * perCartonCost, { decimalPlaces: 0 })}
            />
          </Row>
        </>
      )}
      {goFund && VALID_ACTIVITY_ROWS.includes(goFund.activity) && renderActivityRow(goFund.activity)}

      {goFund.paymentType === GO_FUND_PAYMENT_TYPES.instant && programsPath && (
        <ActionButtons programsPath={programsPath} programId={programId} program={program} goFund={goFund} />
      )}
      {programsPath && (
        <Button secondary full to={`${programsPath}/${programId}`}>
          Details
        </Button>
      )}
    </div>
  )
}

GoFundProgramCard.propTypes = {
  program: object.isRequired,
  programsPath: string
}

export default GoFundProgramCard
