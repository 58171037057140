import React, { useContext, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { bool, func, object, string } from 'prop-types'
import styled from 'styled-components'

import LangContext from 'context/LangContext'

import { submitCustomerProgramUpdate } from 'store/sellInPrograms/actions'

import Button from 'components/button/Button'
import ButtonGroup from 'components/button/ButtonGroup'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import Input from 'components/Input'
import SkuTable from 'components/sellInPrograms/SkuTable'

import { STATUS } from 'utils/constants'

import Questions from './RenderSellInQuestions'

const LastUpdated = ({ className, timestamp }) => {
  return <span className={className}>Last save: {timestamp}</span>
}
LastUpdated.propTypes = {
  className: string,
  timestamp: string
}
const StyledLastUpdated = styled(LastUpdated)`
  flex: 1 0 100%;
  text-align: center;
  color: #188917;
`

const TextFilter = ({ onInputChange, placeholder }) => {
  return (
    <div className="mx-2 my-6 mb-2 flex items-center gap-4 md:mx-0">
      <Input icon="search" onChange={onInputChange} small placeholder={placeholder} className="mb-1.5 flex-1" />
    </div>
  )
}
TextFilter.propTypes = {
  onInputChange: func.isRequired,
  placeholder: string
}

const SellInCustomerDetails = ({
  isVolumeProgram,
  sellInTarget,
  sellInProgram,
  submitCustomerProgramUpdate,
  setExceptionType,
  allowException
}) => {
  const [productsInputValue, setProductsInputValue] = useState()

  const submitContractPrintedAt = () => {
    const { sellInProgramId, customerId } = sellInTarget
    submitCustomerProgramUpdate({ sellInProgramId, customerId, contractLastPrintedAt: new Date().toISOString() })
  }

  const onProductsInputChange = (event) => {
    setProductsInputValue(event.currentTarget.value.trim())
  }

  const { translate } = useContext(LangContext)

  return (
    <Fieldset>
      <Questions
        questions={sellInProgram.questions}
        customerQuestions={sellInTarget.questions}
        isLocked
        answers={sellInTarget.answers || {}}
      />
      {isVolumeProgram && (
        <FieldsetItem>
          <TextFilter
            onInputChange={onProductsInputChange}
            placeholder={translate('components.SellInCustomerForm.productsInputPlaceholder')}
          />
          <SkuTable skus={sellInTarget.skus} filterValue={productsInputValue} />
        </FieldsetItem>
      )}
      {(sellInTarget.contractPdfUrl || allowException) && (
        <FieldsetItem>
          <ButtonGroup>
            {sellInTarget.contractPdfUrl && (
              <Button
                color={sellInProgram.color}
                target="_blank"
                href={sellInTarget.contractPdfUrl}
                onClick={submitContractPrintedAt}
              >
                Print Contract
              </Button>
            )}
            {allowException && (
              <Button color={sellInProgram.color} onClick={() => setExceptionType(STATUS)}>
                Request Exception
              </Button>
            )}
          </ButtonGroup>
        </FieldsetItem>
      )}
      <FieldsetItem>
        <StyledLastUpdated timestamp={moment(sellInTarget.updatedAt).format('MMM D h:mm a')} />
      </FieldsetItem>
    </Fieldset>
  )
}

SellInCustomerDetails.propTypes = {
  isVolumeProgram: bool,
  sellInTarget: object,
  sellInProgram: object,
  submitCustomerProgramUpdate: func,
  setExceptionType: func,
  allowException: bool
}

export default connect(null, { submitCustomerProgramUpdate })(SellInCustomerDetails)
