/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Field, Form, Formik, useFormikContext } from 'formik'
import useErrors from 'hooks/useErrors'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment-timezone'
import { bool, func, object, string } from 'prop-types'
import styled from 'styled-components'

import LangContext from 'context/LangContext'

import { deleteEmployeeOOT, upsertEmployeeOOT } from 'store/employeeOots/actions'
import * as ootSelector from 'store/employeeOots/selectors'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import DatePicker from 'components/DatePicker'
import Dropdown from 'components/Dropdown'
import FieldError from 'components/ErrorMessage'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Input from 'components/Input'
import Label from 'components/Label'
import Toggle from 'components/Toggle'

import { HOUR_OPTIONS, MINUTE_OPTIONS, OOT_CATEGORIES } from 'utils/constants'
import { isWeekend } from 'utils/helpers'
import { required } from 'utils/validators'

import * as spacing from 'styles/spacing'

const Row = styled.div`
  display: flex;
  align-items: center;
`

const Divider = styled.span`
  padding: 0 ${spacing.tiny};
`

const FloatingFieldError = styled(FieldError)`
  padding: ${spacing.small};
  padding-top: 0;
  margin-top: 0;
`

const LND_RULE = '1hrL&D'

const getDefaultValues = () => ({
  startDate: moment().format('YYYY-MM-DD'),
  startHour: moment().format('HH'),
  startMinute: moment().format('mm'),
  endDate: moment().format('YYYY-MM-DD'),
  endHour: moment().format('HH'),
  endMinute: moment().add(20, 'minute').format('mm'),
  kms: '',
  reason: '',
  category: '',
  isAllDay: false,
  name: ''
})

const categoryRequiresKms = (category) => {
  if (!category) return false
  return !['OOT_VACATION', 'OOT_STATUTORY_HOLIDAYS'].includes(category)
}

const ConfirmDeleteOot = ({ confirmDeleteOot, deleteOOT, setConfirmDeleteOot }) => {
  const { translate } = useContext(LangContext)

  const handleCancel = () => setConfirmDeleteOot(false)

  return (
    <ActionSheet title={translate('app.cancelCall')} visible={confirmDeleteOot} onOverlayClick={handleCancel}>
      <div>
        {translate('app.warn.confirm.deleteOOT')}
        <Fieldset>
          <FieldsetItem>
            <Button full primary onClick={deleteOOT}>
              {translate('common.yes')}
            </Button>
          </FieldsetItem>
          <FieldsetItem>
            <Button full onClick={handleCancel}>
              {translate('common.no')}
            </Button>
          </FieldsetItem>
        </Fieldset>
      </div>
    </ActionSheet>
  )
}

ConfirmDeleteOot.propTypes = {
  confirmDeleteOot: bool.isRequired,
  deleteOOT: func.isRequired,
  setConfirmDeleteOot: func.isRequired
}

const OotForm = ({ isGlobal, canEdit, globalError, startWorkHour, startWorkMinute, ootDetails }) => {
  const { translate } = useContext(LangContext)

  const { values, setFieldValue, errors, validateField } = useFormikContext()

  const { startDate, startHour, startMinute, endDate, endHour, endMinute, isAllDay, category } = values

  const allOots = useSelector((state) => ootSelector.allOots(state))

  const ootStart = useMemo(() => {
    return moment(startDate).set({
      hour: +startHour,
      minute: +startMinute,
      seconds: 0,
      millisecond: 0
    })
  }, [startDate, startHour, startMinute])

  const ootEnd = useMemo(() => {
    return moment(endDate).set({
      hour: +endHour,
      minute: +endMinute,
      seconds: 0,
      millisecond: 0
    })
  }, [endDate, endHour, endMinute])

  const isGlobalLnD = ootDetails?.rule === LND_RULE
  useEffect(() => {
    if (isGlobalLnD) setFieldValue('kms', 0)
  }, [ootDetails?.rule])

  const hasKms = useMemo(() => {
    return categoryRequiresKms(category) && !isGlobalLnD
  }, [category])

  const endHourOptions = useMemo(() => {
    if (isAllDay) return []
    const filteredHourOptions = HOUR_OPTIONS.filter(({ value }) => {
      const isValidMinHour = Number(value) >= moment(ootStart).add(5, 'minutes').hour()
      return isValidMinHour // && isValidMaxHour
    })
    return filteredHourOptions
  }, [isAllDay, ootStart])

  const endMinuteOptions = useMemo(() => {
    if (isAllDay) return []
    if (moment(ootEnd).isAfter(ootStart, 'hour')) return MINUTE_OPTIONS

    const filteredMinuteOptions = MINUTE_OPTIONS.filter(({ value }) => {
      return Number(value) > moment(ootStart).minute()
    })
    return filteredMinuteOptions
  }, [isAllDay, ootStart, ootEnd])

  useLayoutEffect(() => {
    if (!endHourOptions.length) return
    const firstHourAllowed = endHourOptions[0].value
    if (Number(firstHourAllowed) > moment(ootEnd).hour()) {
      setFieldValue('endHour', firstHourAllowed)
      validateField('startHour')
    }
  }, [endHourOptions, ootEnd])

  useLayoutEffect(() => {
    if (!endMinuteOptions.length) return
    const firstMinuteAllowed = endMinuteOptions[0].value
    if (Number(firstMinuteAllowed) > moment(ootEnd).minute()) {
      setFieldValue('endMinute', firstMinuteAllowed)
    }
  }, [endMinuteOptions, ootEnd])

  useLayoutEffect(() => {
    if (isGlobalLnD) {
      const LnDOotEnd = moment(ootStart).add(1, 'hour')
      setFieldValue('endDate', moment(LnDOotEnd).format('YYYY-MM-DD'))
      setFieldValue('endHour', moment(LnDOotEnd).format('HH'))
      setFieldValue('endMinute', moment(LnDOotEnd).format('mm'))
    }
  }, [startDate, startHour, startMinute, isGlobalLnD])

  const isDuringAnotherOot = (oot, startDate, endDate) => {
    const isEditingOot = ootDetails && ootDetails.id && ootDetails.id === oot.id
    return (
      (moment(startDate).isBetween(oot.startAt, oot.endAt, 'day', '[]') ||
        moment(endDate).isBetween(oot.startAt, oot.endAt, 'day', '[]')) &&
      !isEditingOot
    )
  }

  const isDuringGlobalOot = useMemo(() => {
    if (!startDate || !endDate) return null

    return allOots
      .filter(({ rule }) => rule === null || rule !== LND_RULE)
      .some((oot) => {
        return isDuringAnotherOot(oot, startDate, endDate) && (oot.isGlobal || oot.globalOotId)
      })
  }, [startDate, endDate])

  const isDuringStatHoliday = useMemo(() => {
    const { category } = values
    if (!startDate || !endDate) return null

    if (
      category === 'OOT_STATUTORY_HOLIDAYS' &&
      allOots.some((oot) => isDuringAnotherOot(oot, startDate, endDate) && oot.category === 'OOT_STATUTORY_HOLIDAYS')
    ) {
      return translate('createOOTSheet.warn.statutoryOOTAlreadyLogged')
    }

    return null
  }, [startDate, endDate, values?.category, allOots])

  const validateKms = (value) => {
    if (+value < 0) return 'Mileage must be 0 or greater'
    return null
  }

  const GLOBAL_OOT_MAX_HOURS = 4
  const FULL_DAY_OOT_THRESHOLD_HOURS = 8

  const validateTime = (value) => {
    if (!moment(ootStart).isValid() || !moment(ootEnd).isValid()) return translate('app.warn.enterValidStartEnd')
    else if (!isAllDay && moment(ootStart).isSameOrAfter(ootEnd, 'minute'))
      return translate('createOOTSheet.warn.OOTEndMustBeAfterStart')
    else if (isDuringGlobalOot && moment(ootEnd).diff(ootStart, 'hours') > GLOBAL_OOT_MAX_HOURS)
      return translate('createOOTSheet.warn.globalOOTMaxHours', { hoursLimit: GLOBAL_OOT_MAX_HOURS })
    else if (!isAllDay && moment(ootEnd).diff(ootStart, 'hours') > FULL_DAY_OOT_THRESHOLD_HOURS)
      return translate('createOOTSheet.warn.thresholdFullDayOOT', { hoursThreshold: FULL_DAY_OOT_THRESHOLD_HOURS })

    return null
  }

  const blockedEditDays = (d) => {
    const dayIsWeekend = isWeekend(d)
    if (!isGlobalLnD) return dayIsWeekend

    const dateIsBeforeStartOfWeek = moment(d).isBefore(moment.max(moment(), moment(ootDetails.startAt).day(1)), 'day')
    const dateIsPastEndOfWeek = moment(d).isAfter(moment(ootDetails.startAt).day(5), 'day')

    return dayIsWeekend || dateIsPastEndOfWeek || dateIsBeforeStartOfWeek
  }

  useLayoutEffect(() => {
    if (isWeekend(ootStart)) {
      const nextValidDay = moment(ootStart).add(1, 'day').day(1)
      setFieldValue('startDate', moment(nextValidDay).format('YYYY-MM-DD'))
      setFieldValue('startHour', moment(ootStart).format('HH'))
      setFieldValue('startMinute', moment(ootStart).format('mm'))

      if (!isAllDay) {
        setFieldValue('endDate', moment(nextValidDay).format('YYYY-MM-DD'))
        setFieldValue('endHour', moment(ootEnd).format('HH'))
        setFieldValue('endMinute', moment(ootEnd).format('mm'))
      }
    }
  }, [ootStart, isAllDay, ootEnd])

  useLayoutEffect(() => {
    if (isWeekend(ootEnd) && isAllDay) {
      const lastValidDay = moment.max([moment(ootEnd).subtract(1, 'day').day(5), moment(ootStart)])

      setFieldValue('endDate', moment(lastValidDay).format('YYYY-MM-DD'))
      setFieldValue('endHour', moment(ootEnd).format('HH'))
      setFieldValue('endMinute', moment(ootEnd).format('mm'))
    }
  }, [ootEnd, isAllDay, ootStart])

  useLayoutEffect(() => {
    const datesDifferentForSingleDayOOT = !isAllDay && !moment(ootStart).isSame(ootEnd, 'date')
    const startAfterEndForMultiDayOOT = isAllDay && moment(ootStart).isAfter(ootEnd, 'date')

    if (datesDifferentForSingleDayOOT || startAfterEndForMultiDayOOT) {
      setFieldValue('endDate', moment(startDate).format('YYYY-MM-DD'))
    }
  }, [isAllDay, ootStart, ootEnd])

  useEffect(() => {
    validateField('startHour')
    validateField('startMinute')
    validateField('endHour')
    validateField('endMinute')
  }, [startDate, endDate, startHour, endHour, startMinute, endMinute])

  return (
    <Fieldset>
      {isGlobal && !canEdit && (
        <FieldsetItem>
          <GlobalAlert warning>{translate('createOOTSheet.warn.globalOOTOnlyEditableByAdmin')}</GlobalAlert>
        </FieldsetItem>
      )}
      <FieldsetItem>
        <Field name="name" validate={required} disabled={isGlobal}>
          {({ field }) => (
            <Input
              text
              label={translate('app.OOTName')}
              maxLength={255}
              disabled={!canEdit || isGlobalLnD}
              {...field}
            />
          )}
        </Field>

        {errors.name && <FloatingFieldError>Required</FloatingFieldError>}
      </FieldsetItem>
      <FieldsetItem>
        <Field name="startDate">
          {({ field }) => (
            <DatePicker
              label={translate('common.startDate')}
              value={field.value}
              onChange={(val) => setFieldValue('startDate', val)}
              isOutsideRange={() => false}
              isDayBlocked={blockedEditDays}
              disabled={!canEdit}
            />
          )}
        </Field>
      </FieldsetItem>
      {values.isAllDay ? (
        <FieldsetItem>
          <Field name="endDate" validate={required}>
            {({ field }) => (
              <DatePicker
                label={translate('common.endDate')}
                value={field.value}
                onChange={(val) => setFieldValue('endDate', val)}
                isOutsideRange={() => false}
                isDayBlocked={blockedEditDays}
                disabled={!canEdit}
              />
            )}
          </Field>

          {errors.endDate && <FloatingFieldError>{translate('common.required')}</FloatingFieldError>}
        </FieldsetItem>
      ) : (
        <>
          <FieldsetItem half>
            <Label>{translate('app.startTime')}</Label>
            <Row>
              <Field name="startHour" validate={validateTime}>
                {({ field }) => <Dropdown noIcon options={HOUR_OPTIONS} disabled={!canEdit} {...field} />}
              </Field>
              <Divider>:</Divider>
              <Field name="startMinute" validate={validateTime}>
                {({ field }) => <Dropdown noIcon options={MINUTE_OPTIONS} disabled={!canEdit} {...field} />}
              </Field>
            </Row>
          </FieldsetItem>
          <FieldsetItem half>
            <Label>{translate('app.endTime')}</Label>
            <Row>
              <Field name="endHour" validate={validateTime}>
                {({ field }) => (
                  <Dropdown noIcon options={endHourOptions} disabled={!canEdit || isGlobalLnD} {...field} />
                )}
              </Field>
              <Divider>:</Divider>
              <Field name="endMinute" validate={validateTime}>
                {({ field }) => (
                  <Dropdown noIcon options={endMinuteOptions} disabled={!canEdit || isGlobalLnD} {...field} />
                )}
              </Field>
            </Row>
          </FieldsetItem>
          {(errors.startHour || errors.startMinute || errors.endHour || errors.endMinute) && (
            <FloatingFieldError>
              {errors.startHour || errors.startMinute || errors.endHour || errors.endMinute}
            </FloatingFieldError>
          )}
        </>
      )}

      {!isDuringGlobalOot && !isGlobalLnD && (
        <FieldsetItem>
          <Label small>{translate('common.allDay')}</Label>
          <Field name="isAllDay">
            {({ field }) => (
              <Toggle
                checked={Boolean(field.value)}
                isDisabled={!canEdit || isGlobal || ootDetails?.globalOotId}
                onClick={(val) => {
                  if (!canEdit || isGlobal || ootDetails?.globalOotId) return
                  setFieldValue('isAllDay', !val.currentTarget.checked)
                }}
                {...field}
              />
            )}
          </Field>
        </FieldsetItem>
      )}
      <FieldsetItem>
        <Field name="category" validate={required}>
          {({ field }) => (
            <Dropdown
              // secondary
              placeholder={translate('app.placeholders.selectCategory')}
              options={
                !isDuringGlobalOot ? OOT_CATEGORIES : OOT_CATEGORIES.filter((c) => c.value !== 'OOT_STATUTORY_HOLIDAYS')
              }
              disabled={!canEdit || isGlobalLnD}
              {...field}
            />
          )}
        </Field>
      </FieldsetItem>
      {hasKms && (
        <FieldsetItem>
          <Field name="kms" validate={(value) => required(value) || validateKms(value)}>
            {({ field }) => (
              <Input
                label={translate('common.mileage')}
                type="number"
                placeholder="0"
                min={0}
                disabled={!canEdit}
                {...field}
              />
            )}
          </Field>
          {errors.kms && <FieldError>{errors.kms}</FieldError>}
        </FieldsetItem>
      )}
      <FieldsetItem>
        <Field name="reason">
          {({ field }) => <Input textarea maxLength={255} disabled={!canEdit || isGlobalLnD} {...field} />}
        </Field>
      </FieldsetItem>
      {isDuringStatHoliday && (
        <FieldsetItem>
          <GlobalAlert>{isDuringStatHoliday}</GlobalAlert>
        </FieldsetItem>
      )}
      {globalError && <GlobalAlert>{globalError}</GlobalAlert>}
    </Fieldset>
  )
}

OotForm.propTypes = {
  isGlobal: bool,
  canEdit: bool,
  globalError: string,
  startWorkHour: string,
  startWorkMinute: string,
  ootDetails: object
}

const CreateOOTSheet = ({
  visibleScheduleScreen,
  setVisibleScheduleScreen,
  employeeWorkHours,
  ootDetails,
  upsertEmployeeOOT,
  deleteEmployeeOOT,
  employeeKm,
  canEdit,
  isGlobal
}) => {
  const { translate } = useContext(LangContext)

  const [confirmDeleteOot, setConfirmDeleteOot] = useState(false)
  const { globalError, setGlobalError, generateErrorMessage } = useErrors()

  const employeeTimezone = useSelector(
    (state) => state.auth.user?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  const [startWorkHour, startWorkMinute] = (employeeWorkHours?.start || '09:00').split(':')
  const [endWorkHour, endWorkMinute] = (employeeWorkHours?.end || '17:00').split(':')

  const isModifying = Boolean(ootDetails?.id)

  const initialValues = useMemo(() => {
    if (!isEmpty(ootDetails)) {
      return {
        startDate: moment(ootDetails.startAt).format('YYYY-MM-DD'),
        startHour: moment(ootDetails.startAt).format('HH'),
        startMinute: moment(ootDetails.startAt).format('mm'),
        endDate: moment(ootDetails.endAt).format('YYYY-MM-DD'),
        endHour: moment(ootDetails.endAt).format('HH'),
        endMinute: moment(ootDetails.endAt).format('mm'),
        reason: ootDetails.reason,
        category: ootDetails.category,
        isAllDay: ootDetails.isAllDay,
        name: ootDetails.name,
        kms:
          ootDetails.employeeKm && ootDetails.employeeKm.kms
            ? ootDetails.employeeKm.kms
            : [LND_RULE].includes(ootDetails?.rule)
            ? 0
            : null
      }
    }

    return getDefaultValues()
  }, [ootDetails])

  const submitOOT = async (values, actions) => {
    const { startDate, startHour, startMinute, endDate, endHour, endMinute, kms, reason, category, isAllDay, name } =
      values

    try {
      const ootStart = moment(startDate).set({ hour: +startHour, minute: +startMinute, second: 0, millisecond: 0 })
      const ootEnd = moment(endDate).set({ hour: +endHour, minute: +endMinute, second: 0, millisecond: 0 })

      const startAt = isAllDay
        ? moment(ootStart).set({ hour: +startWorkHour, minute: +startWorkMinute, second: 0, millisecond: 0 }) // if all day, set start to work start time
        : moment(ootStart) // if all day, set start to work start time

      const endAt = isAllDay
        ? moment(ootEnd).set({ hour: +endWorkHour, minute: +endWorkMinute, second: 0, millisecond: 0 }) // if all day, set endtime to work end time
        : moment.min([moment(ootEnd), moment(ootStart).add(8, 'hour')]) // if not all day, cap OOT length to 8 hrs

      const ootUpdate = {
        ...(ootDetails || null),
        startAt: moment.tz(startAt, employeeTimezone).toISOString(),
        endAt: moment.tz(endAt, employeeTimezone).toISOString(),
        isAllDay,
        category,
        reason,
        name
      }

      if (categoryRequiresKms(category) && +kms >= 0) {
        ootUpdate.employeeKm = {
          ...employeeKm,
          kms,
          dateDriven: moment(ootStart).toISOString(),
          type: 'OOT'
        }
      }
      await upsertEmployeeOOT(ootUpdate)

      actions.resetForm()
      setVisibleScheduleScreen(null)
    } catch (err) {
      console.error(err)
      const errorKey = isModifying ? 'createOOTSheet.errors.updateOOT' : 'createOOTSheet.errors.createOOT'
      setGlobalError(generateErrorMessage(errorKey, err))
    }
  }

  const cancelOot = () => {
    setVisibleScheduleScreen(null)
  }

  const deleteOOT = async () => {
    await deleteEmployeeOOT(ootDetails)

    setVisibleScheduleScreen()
    setConfirmDeleteOot(false)
  }

  const cannotDelete = isGlobal || [LND_RULE].includes(ootDetails?.rule)

  const title = isModifying ? 'app.editOOT' : 'app.createOOT'

  return (
    <div>
      <ActionSheet
        title={translate(title)}
        visible={visibleScheduleScreen}
        action={
          <button type="button" onClick={cancelOot}>
            {translate('common.cancel')}
          </button>
        }
        onOverlayClick={cancelOot}
      >
        <Formik initialValues={initialValues} onSubmit={submitOOT} enableReinitialize>
          {({ submitForm, isSubmitting, errors, values }) => (
            <Form>
              <div>
                <OotForm
                  isGlobal={isGlobal}
                  canEdit={canEdit}
                  globalError={globalError}
                  startWorkHour={startWorkHour}
                  startWorkMinute={startWorkMinute}
                  ootDetails={ootDetails}
                />
                <Fieldset>
                  {canEdit && (
                    <FieldsetItem>
                      <Button
                        full
                        primary
                        disabled={isSubmitting || !isEmpty(errors)}
                        onClick={submitForm}
                        isLoading={isSubmitting}
                      >
                        {isModifying ? translate('common.save') : translate('common.create')}
                      </Button>
                    </FieldsetItem>
                  )}

                  {!cannotDelete && isModifying && canEdit && (
                    <FieldsetItem>
                      <Button
                        full
                        secondary
                        onClick={() => setConfirmDeleteOot(true)}
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                      >
                        {translate('app.deleteOOT')}
                      </Button>
                    </FieldsetItem>
                  )}
                </Fieldset>
              </div>
            </Form>
          )}
        </Formik>
      </ActionSheet>

      <ConfirmDeleteOot
        confirmDeleteOot={confirmDeleteOot}
        deleteOOT={deleteOOT}
        setConfirmDeleteOot={setConfirmDeleteOot}
      />
    </div>
  )
}

CreateOOTSheet.propTypes = {
  visibleScheduleScreen: bool.isRequired,
  setVisibleScheduleScreen: func.isRequired,
  upsertEmployeeOOT: func.isRequired,
  deleteEmployeeOOT: func.isRequired,
  ootDetails: object,
  employeeWorkHours: object,
  employeeKm: object,
  canEdit: bool,
  isGlobal: bool
}

export default connect(null, {
  upsertEmployeeOOT,
  deleteEmployeeOOT
})(CreateOOTSheet)
